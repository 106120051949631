import type { PlatformModuleBase } from './types';
import type { I18nLocale, RehydrateState } from '../../clients';
import type { NamedLogger } from '../../clients/logger/named';
import type { CreateOptions, CreateResult, OpenOptions, OpenResult } from '../services/plane/types';
import type { AppRailDrawerIds, LayoutState } from '../store/reducers/layout/types';
import type { NestedPlaneState, Plane, PlaneId } from '../store/reducers/planes';
import type { CreateSnackbarArg } from '../store/reducers/snackbar/types';
import type { UserSettings } from '../store/reducers/user/types';
import { useMemo } from 'react';
import PlatformCore from '..';
import { ModuleClient, RehydrateStateClient, TelemetryClient } from '../../clients';
import StaticPlatformModule from './static';

/**
 * IntegratedPlatformModule is the interface that is given to a remote module when we go to render their remote content.
 * It represents a fully configured module instance that has the ability and access to interact with Platform and includes
 * functionality around interacting with a Plane, providing a structured way to manage and regulate these interactions.
 * This class serves as a centralized location to expose a public API for all remote module interactions where we can
 * control and manage what interactions we expose to them.
 */
export class IntegratedPlatformModule implements PlatformModuleBase {
  #logger: NamedLogger | undefined;
  #planeId: PlaneId;

  /**
   * Will get or create a rehydrate state for the plane.
   */
  get #rehydrateState(): RehydrateState | undefined {
    if (!this.plane) {
      return undefined;
    }

    const rehydrateStateId = [this.plane.id, this.plane.cloudStorageId].join('-');
    return RehydrateStateClient.getOrCreate(rehydrateStateId);
  }

  /**
   * Get user settings.
   */
  get settings(): UserSettings {
    return StaticPlatformModule.settings;
  }

  /**
     * Get user locale.
     */
  get locale(): I18nLocale {
    return StaticPlatformModule.locale;
  }

  /**
   * Gets the plane from the store.
   */
  get plane(): Plane {
    return PlatformCore.selectors.plane(PlatformCore.getState(), this.#planeId);
  }

  /**
   * Get the loading state for the plane.
   */
  get loading(): boolean {
    return PlatformCore.selectors.isPlaneLoading(PlatformCore.getState(), this.#planeId);
  }

  /**
   * Get the open states for the app rail drawers.
   */
  get appRailDrawers(): LayoutState['appRailDrawers'] {
    return PlatformCore.selectors.layout(PlatformCore.getState()).appRailDrawers;
  }

  /**
   * Gets the created logger instance using the plane's module name
   */
  get logger(): NamedLogger {
    if (this.#logger) {
      return this.#logger;
    }

    const telemetryClientName = this.plane.module;
    const telemetryClient = TelemetryClient.createClient(telemetryClientName);
    const loggerName = `${this.plane.module}:${this.#planeId}`;
    this.#logger = PlatformCore.createNamedLogger(loggerName, telemetryClient);
    return this.#logger;
  }

  constructor(planeId: PlaneId) {
    if (!planeId) {
      throw new Error('IntegratedPlatformModule cannot be created without a planeId');
    }

    this.#planeId = planeId;
  }

  /**
   * Sets the loading state.
   * @param loading - The loading state to set.
   */
  setLoading(loading: boolean): void {
    PlatformCore.actions.setPlaneLoading(this.#planeId, loading);
  }

  /**
   * Sets the open state of an app rail drawer.
   * @param appRailDrawerId - The ID of the app rail drawer.
   * @param open - The open state to set.
   */
  setAppRailDrawerOpen(appRailDrawerId: AppRailDrawerIds, open: boolean): void {
    PlatformCore.actions.setAppRailDrawerOpen(appRailDrawerId, open);
  }

  /**
   * Sets the dirty state.
   * @param planeId - The ID of the plane.
   * @param dirty - Dirty state
   */
  setDirty(dirty: boolean): void {
    PlatformCore.actions.updatePlane(this.#planeId, { dirty });
  }

  /**
   * Sets the error state.
   * @param error - The error to set.
   */
  setError(error: Error): void {
    PlatformCore.actions.setPlaneError(this.#planeId, error);
  }

  /**
   * Sets the state of a specific plane.
   * @param state - The state to set for the plane.
   */
  setState(state: NestedPlaneState): void {
    PlatformCore.actions.updatePlaneState(this.#planeId, state);
  }

  /**
   * Sets the plane's rehydrate state.
   * @param rehydrateState - The rehydrate state to set.
   */
  setRehydrateState(state: unknown): void {
    if (!this.plane) {
      return;
    }

    const rehydrateState = this.#rehydrateState;
    const sanitizer = ModuleClient.getRemoteModuleConfig(this.plane.module)?.rehydrateState.sanitizer;
    const transformedState = sanitizer ? sanitizer(state) : state;
    rehydrateState?.set(transformedState);
  }

  /**
   * Get the plane's rehydrate state.
   * @returns The rehydrate state.
   */
  getRehydrateState(): unknown {
    return this.#rehydrateState?.get();
  }

  /**
   * Clear the plane's rehydrate state.
   */
  clearRehydrateState(): void {
    this.#rehydrateState?.clear();
  }

  /**
   * Handler to close the platform module's plane.
   */
  async closePlane(): Promise<void> {
    await PlatformCore.Plane.closePlane(this.#planeId);
  }

  /**
   * Creates a new MSSO analysis plane with the given options.
   * @param options - The options to create the analysis with.
   * @returns The created analysis plane.
   */
  async createAnalysis(options: CreateOptions = {}): CreateResult {
    return await PlatformCore.Plane.createAnalysis(options);
  }

  /**
   * Creates a new Reporting Tool plane with the given options.
   * @param options - The options to create the analysis with.
   * @returns The created analysis plane.
   */
  async createReport(options: CreateOptions = {}): CreateResult {
    return await PlatformCore.Plane.createReportTool(options);
  }

  /**
   * Opens an analysis plane with the given options.
   * @param options - The options to open the analysis with.
   * @returns The opened analysis plane.
   */
  async openAnalysis(options: OpenOptions): OpenResult {
    return await PlatformCore.Plane.openAnalysis(options);
  }

  /**
   * Adds a snackbar (toast) notification to the store to be displayed in the Platform client associated
   * with the plane. If a null planeId is given the snack will be displayed in the main Platform client.
   * @param snackbar
   */
  showSnackbar(snackbar: CreateSnackbarArg): void {
    PlatformCore.Snackbar.showSnackbar({
      ...snackbar,
      planeId: snackbar.planeId ?? this.#planeId,
    });
  }

  /**
   * Uses a selector for claims.
   */
  useClaims(): unknown {
    const claims = StaticPlatformModule.useClaims();
    console.log(claims);
    const transformedClaims = useMemo(() => {
      if (!this.plane) {
        return undefined;
      }
      const transformer = ModuleClient.getRemoteModuleConfig(this.plane.module)?.claims.transformer;
      return transformer?.(claims) ?? claims;
    }, [claims]);
    return transformedClaims;
  }

  /**
   * Uses a selector to get the user's locale settings.
   * @returns The locale settings.
   */
  useLocale(): I18nLocale | undefined {
    return StaticPlatformModule.useLocale();
  }

  /**
   * Hooks into the plane loading state.
   * @returns The plane loading state.
   */
  useLoading(): string | boolean {
    return PlatformCore.useSelector(state => PlatformCore.selectors.isPlaneLoading(state, this.#planeId));
  }

  /**
   * Selects the current plane from the store.
   * @returns The plane.
   */
  usePlane(): Plane | undefined {
    return PlatformCore.useSelector(state => PlatformCore.selectors.plane(state, this.#planeId));
  }
}

export default IntegratedPlatformModule;
