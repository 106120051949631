import type { ModuleConfigKey } from './modules/types';
import type { InitializeDialogsOptions, InitializeI18nOptions } from '../clients';
import { CloudStorage, CloudStorageApi } from '@mtb/cloud-storage';
import { DialogsClient, I18nClient, LoggerClient, ModuleClient, TelemetryClient } from '../clients';
import V2EventHandler from '../clients/deprecated/v2-event-handler';
import config from '../config';
import ModuleConfig from '../module-config';
import * as CONSTANTS from './constants';
import HealthService from './services/health';
import PlaneService from './services/plane';
import RoutingService from './services/routing';
import SnackbarService from './services/snackbar';
import UserService from './services/user';
import { PlatformProvider, platformStore, useDispatch, useSelector, useStore } from './store';
import remoteModuleUrlConfigs from './utils/remote-url-configs';

// Let cloud storage know it can initialize and give it the config values.
if (config.feature_flag_cs_store_v2) {
  CloudStorageApi.initialize(config);
} else {
  CloudStorage.initialize(config);
}

/**
 * Represents the core functionality of Platform
 */
export class PlatformCore {
  #registeredModules = new Map<ModuleConfigKey, ModuleConfig>();

  /**
   * The createNamedLogger function for PlatformClient to create named loggers.
   */
  get createNamedLogger() {
    return LoggerClient.createNamedLogger;
  }

  /**
   * The remote module URL configurations.
   */
  #remoteModuleUrlConfigs: typeof remoteModuleUrlConfigs = remoteModuleUrlConfigs;
  get remoteModuleUrlConfigs() {
    return this.#remoteModuleUrlConfigs;
  }

  /**
   * The constants that are available in the platform.
   */
  get constants() {
    return CONSTANTS;
  }

  /**
   * The modules that are available in the platform.
   */
  get Modules() {
    return ModuleClient;
  }

  /**
   * Returns a function that can be used to get state from the store.
   */
  get getState() {
    return platformStore.getState;
  }

  /**
   * Returns the predefined, memoized selectors for the store.
   */
  get selectors() {
    return platformStore.selectors;
  }

  /**
   * Returns a function that can be used to subscribe changes in the store.
   */
  get subscribe() {
    return platformStore.subscribe;
  }

  /**
   * Returns a function that can be used to dispatch actions to the store.
   */
  get actions() {
    return platformStore.actions;
  }

  /**
   * Returns the useSelector hook from the store.
   */
  get useSelector() {
    return useSelector;
  }

  /**
   * Returns the useDispatch hook from the store.
   */
  get useDispatch() {
    return useDispatch;
  }

  /**
   * Returns the useStore hook from the store.
   */
  get useStore() {
    return useStore;
  }

  /**
   * Returns the Provider component from the store.
   */
  get Provider() {
    return PlatformProvider;
  }

  #dialogs = DialogsClient;
  get Dialogs() {
    return this.#dialogs;
  }

  #i18n = I18nClient;
  get I18n() {
    return this.#i18n;
  }

  #snackbar = new SnackbarService();
  get Snackbar() {
    return this.#snackbar;
  }

  #plane = new PlaneService();
  get Plane() {
    return this.#plane;
  }

  #routing = new RoutingService();
  get Routing() {
    return this.#routing;
  }

  #health = new HealthService();
  get Health() {
    return this.#health;
  }

  #eventHandler = V2EventHandler;
  get Events() {
    return this.#eventHandler;
  }

  #logger = LoggerClient.createNamedLogger('PlatformCore');
  get Logger() {
    return this.#logger;
  }

  #user = new UserService();
  get User() {
    return this.#user;
  }

  constructor() {
    // Expose Platform to the window object in development mode.
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore - Ignore window type error.
    if (config.dev_tools_enabled) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore - Ignore window type error.
      window.Platform = this;
    }

    // TODO: Move to plane service.
    window.addEventListener('beforeunload', () => {
      const planes = this.getState().planes;
      for (const plane of Object.values(planes)) {
        this.#eventHandler.cleanup(plane);
      }
    });
  }

  /**
   * Initialize Platform with the given options to better
   * control aspects of client behavior when performing inner platform operations.
   * @param options The options to initialize the platform with.
   * @param options.i18n The options to initialize the i18n client with.
   * @param options.dialogs The options to initialize the dialogs client with.
   * @param authClient - The authentication client to be used.
   */
  initialize(options: {
    i18n: InitializeI18nOptions;
    dialogs: InitializeDialogsOptions;
  }) {
    I18nClient.initialize(options.i18n);
    DialogsClient.initialize(options.dialogs);
    this.#user.updateLocale(this.I18n.detectLocale());
  }

  /**
   * Creates a non auto tracking version of the telemetry client for remote modules.
   * @param clientName - The name of the telemetry client.
   * @returns The remote module telemetry client.
   */
  createTelemetryClient(clientName: string) {
    return TelemetryClient.createClient(clientName);
  }

  /**
   * Creates a module using the given module key.
   * @param moduleKey - The key of the module.
   * @deprecated Use v3 init-remote-module init api instead.
   */
  createModule(moduleKey: ModuleConfigKey) {
    if (this.#registeredModules.has(moduleKey)) {
      return this.#registeredModules.get(moduleKey) as ModuleConfig;
    }

    const module = ModuleConfig.Create(moduleKey, this);

    this.#registeredModules.set(moduleKey, module);
    return module;
  }

  /**
   * @param {ModuleConfig} moduleConfig
   * @deprecated Use v3 init-remote-module init api instead.
   * @todo Remove this function once everyone has switched to the v3 init-remote-module api or higher.
   */
  register(moduleConfig: ModuleConfig) {
    this.Events.register(moduleConfig);
  }
}

const platformCore = new PlatformCore();
export default platformCore;
