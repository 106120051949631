import type { ModuleConfigKey } from '../../core/modules/types';
import type RemoteModuleConfigEvents from '../../core/remote-module-config/events';
import type { Plane } from '../../core/store/reducers/planes';
import type { OnOpenFileInfo } from '../deprecated/types';
import { waitForCondition } from '@mtb/utilities';
import PlatformModule from '../../core/platform-module';
import ModuleConfigClient from './config';

/**
 * ModuleEventClient manages any events configured by the remote module during the `init` function.
 * It stores the events in a map by module key for later access and integration within Platform.
 */
class ModuleEventClient {
  #WAIT_FOR_MODULE_TIMEOUT = 15000;
  #queuedEvents: Map<ModuleConfigKey, Array<() => Promise<unknown>>>;

  constructor() {
    this.#queuedEvents = new Map();
  }

  /**
   * Events can't guarantee that the module is loaded, so we may need to optionally queue the event
   * @param plane - the plane the event was fired on
   * @param event - the "on" event name
   * @param args - additional arguments to pass to the event
   */
  async #fireOrQueueEvent(plane: Plane, event: keyof RemoteModuleConfigEvents, ...args: unknown[]): Promise<void> {
    const fireEvent = async () => {
      const remoteModuleConfig = ModuleConfigClient.getRemoteModuleConfig(plane.module);
      const planeOrPlatformModule = remoteModuleConfig?.events.platformModuleArg
        ? PlatformModule.getOrCreateIntegratedPlatformModule(plane.id)
        : plane;
      // @ts-expect-error TS doesn't like the spread args here
      await remoteModuleConfig?.events?.[event](planeOrPlatformModule, ...args);
    };

    const module = ModuleConfigClient.getRemoteModuleConfig(plane.module);
    if (module) {
      return await fireEvent();
    }

    let resolve: (result?: unknown) => void;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let reject: (reason: any) => void;
    const eventPromise = new Promise<unknown>(async (res, rej) => {
      resolve = res;
      reject = rej;
      await waitForCondition(
        () => Boolean(ModuleConfigClient.getRemoteModuleConfig(plane.module)),
        this.#WAIT_FOR_MODULE_TIMEOUT,
      );
      if (!ModuleConfigClient.getRemoteModuleConfig(plane.module)) {
        rej(new Error('Timed Out'));
      }
    });

    const queueEvent = async () => {
      try {
        const result = await fireEvent();
        resolve(result);
      } catch (e) {
        reject(e);
      }
    };

    const moduleQueue = this.#queuedEvents.get(plane.module);
    if (!moduleQueue) {
      this.#queuedEvents.set(plane.module, [queueEvent]);
    } else {
      moduleQueue.push(queueEvent);
    }

    await eventPromise;
  }

  /**
   * Flushes all queued events.
   *
   */
  async flushEvents(moduleKey: ModuleConfigKey): Promise<void> {
    const queuedEvents = this.#queuedEvents.get(moduleKey);
    while (queuedEvents?.length) {
      await queuedEvents.shift()?.();
    }
  }

  async pulse(plane: Plane) {
    return await this.#fireOrQueueEvent(plane, 'onPulse');
  }

  async cleanup(plane: Plane) {
    return await this.#fireOrQueueEvent(plane, 'onCleanup');
  }

  async close(plane: Plane) {
    return await this.#fireOrQueueEvent(plane, 'onClose');
  }

  async flush(plane: Plane) {
    return await this.#fireOrQueueEvent(plane, 'onFlush');
  }

  async open(plane: Plane, fileInfo: OnOpenFileInfo) {
    return await this.#fireOrQueueEvent(plane, 'onOpen', fileInfo);
  }
}

const moduleEventClient = new ModuleEventClient();
export default moduleEventClient;
